import './App.css';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import logoltflarge from './images/ftf.png';
import Header from './Header';
import Footer from './Footer';
import { Link } from 'react-router-dom';
import { Button, Typography } from '@mui/material';

function Formulas() {

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Header />
        <section className="main-section" style={{ backgroundColor: '#FFFFFF', color: '#002C52' }}>
        <div className="logo-container">
        <Link to="/">
            <img src={logoltflarge} alt="logo" className="logo-img" style={{ height: 'auto' }} />
        </Link>
        <div>
        <Link to="/map">
          <Button variant="contained" className="three-link" color="primary" backgroundColor="secondary" size="large" style={{fontSize: '1.2rem', fontFamily: 'Multicolore', borderRadius: '0.3rem', margin: '4vw'}}>Map</Button>
          </Link>
          <Link to="/posts">
          <Button variant="contained" className="three-link" color="primary" size="large" style={{fontSize: '1.2rem', fontFamily: 'Multicolore', borderRadius: '0.3rem', margin: '4vw'}}>Posts</Button>
          </Link>
          <Link to="/tags">
          <Button variant="contained" className="three-link" color="primary" size="large" style={{fontSize: '1.2rem', fontFamily: 'Multicolore', borderRadius: '0.3rem', margin: '4vw'}}>Tags</Button>
          </Link>
        </div>
        </div>
        </section>
        <Footer />
      </div>
    </ThemeProvider>
  );
}

export default Formulas;
