import React, { useState, useEffect } from 'react';
import Fab from '@mui/material/Fab';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Fade from '@mui/material/Fade'; // Import Fade

const ScrollTop = () => {
  const [visible, setVisible] = useState(false);

  const checkScroll = () => {
    if (window.scrollY > 100) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', checkScroll);
    return () => window.removeEventListener('scroll', checkScroll);
  }, []);

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Wrap the Fab component with Fade and use the visible state as the in prop
  return (
    <Fade in={visible} timeout={800}>
      <Fab color="secondary" size="small" onClick={handleClick} style={{ position: 'fixed', bottom: '16px', right: '16px', backgroundColor: '#yourColor' }}>
        <KeyboardArrowUpIcon />
      </Fab>
    </Fade>
  );
};

export default ScrollTop;
