import './App.css';
import { ThemeProvider, Snackbar } from '@mui/material';
import theme from './theme';
import { Link } from 'react-router-dom';
import logolarge from './images/ftf.png';
import Header from './Header';
import Footer from './Footer';
import { Box, TextField, Button, Typography, Alert } from '@mui/material';
import { ClassNames } from '@emotion/react';
import { useState, useEffect } from 'react';

function Contact() {
  const [open, setOpen] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [error, setError] = useState('');


  const handleSubmit = (e) => {
    console.log("handleSubmit called");
    e.preventDefault(); // Prevent the browser's default form submission behavior
    const formData = new FormData(e.target);

    fetch("https://findingtheformula.com/contactform.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((data) => {
            throw new Error(data.message || "Network response was not ok");
          });
        }
        return response.json();
      })
      .then((data) => {
        if (data.success) {
          setOpen(true);
        } else {
          setError(data.message || "Something went wrong.");
          setOpenError(true);
        }
      })
      .catch((error) => {
        setError(
          error.message || "There was a problem with the fetch operation."
        );
        setOpenError(true);
      });
    return false;
  };

  

  useEffect(() => {
    document.title = "Contact - Finding the Formula";
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Header />
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={() => setOpen(false)}
        >
          <Alert onClose={() => setOpen(false)} severity="success">
            Successfully submitted!
          </Alert>
        </Snackbar>
        <Snackbar
          open={openError}
          autoHideDuration={6000}
          onClose={() => setOpenError(false)}
        >
          <Alert onClose={() => setOpenError(false)} severity="error">
            {error}
          </Alert>
        </Snackbar>
        <section
          className="main-section"
          style={{
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.primary.main,
          }}
        >
          
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.secondary.main,
              padding: 2,
              borderRadius: "0.75rem",
              maxWidth: {
                xs: "95vw",
                sm: "80vw",
                md: "60vw",
                lg: "35vw",
              },
              minWidth: {
                lg: "35vw",
                md: "55vw",
                sm: "70vw",
              },
              margin: "5vh auto 5vh auto",
            }}
            method="post"
            id="feedbackForm"
          >
            <Box component="div" sx={{ textAlign: "center", marginBottom: 3 }}>
              <Typography
                variant="h1"
                sx={{
                  fontSize: {
                    xs: "1.5rem",
                    sm: "2.5rem",
                    md: "3rem",
                    lg: "3rem",
                  },
                }}
              >
                Contact
              </Typography>
            </Box>
            <p>Name</p>
            <TextField
              className={ClassNames.textField}
              id="name"
              name="name"
              label="Name"
              variant="filled"
              color="secondary"
              fullWidth
              sx={{ marginBottom: 2, background: "#fff" }}
              InputLabelProps={{
                style: { color: theme.palette.fadedgrey.main },
              }}
            />
            <p>Email</p>
            <TextField
              className={ClassNames.textField}
              id="email"
              name="email"
              label="Email Address"
              variant="filled"
              color="secondary"
              fullWidth
              sx={{ marginBottom: 2, background: "#fff" }}
              InputLabelProps={{
                style: { color: theme.palette.fadedgrey.main },
              }}
            />
            <p>Message</p>
            <TextField
              className={ClassNames.textField}
              id="message"
              name="message"
              label="Message"
              variant="filled"
              multiline
              rows={4}
              color="secondary"
              fullWidth
              sx={{ marginBottom: 2, background: "#fff" }}
              InputLabelProps={{
                style: { color: theme.palette.fadedgrey.main },
              }}
            />
            <p>What number comes after three?</p>
            <TextField
              className={ClassNames.textField}
              id="shereal"
              name="shereal"
              label="Please do not be a robot"
              variant="filled"
              color="secondary"
              fullWidth
              sx={{ marginBottom: 2, background: "#fff" }}
              InputLabelProps={{
                style: { color: theme.palette.fadedgrey.main },
              }}
            />
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              size="large"
              sx={{ alignSelf: "center", marginTop: 2 }}
            >
              Send
            </Button>
          </Box>
        </section>

        <Footer />
      </div>
    </ThemeProvider>
  );
}

export default Contact;
