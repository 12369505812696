import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { ThemeProvider } from "@mui/material/styles";
import Header from "./Header";
import Footer from "./Footer";
import theme from "./theme";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { Link } from "react-router-dom";
import NotFound from "./NotFound";
import CardMedia from "@mui/material/CardMedia";
import logolarge from "./images/ftf.png";


const truncate = (str, n) => {
  return str.length > n ? str.substr(0, n - 1) + "..." : str;
};

function PostList() {
  const [articles, setArticles] = useState([]);
  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await axios.get(
          `https://api.findingtheformula.com/api/articles?populate=photo&populate=tags`
        );
        if (response.data && response.data.data.length > 0) {
          console.log(response.data);
          setArticles(response.data.data);
        } else {
          console.log("No articles found for this city.");
        }
      } catch (error) {
        console.error("Failed to fetch articles:", error);
      }
    };

    fetchArticles();
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Header />
        <div className="main-section">
          <Box
            sx={{
              margin: "auto",
              marginTop: "50px",
              marginBottom: "50px",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            <Grid container spacing={2} justifyContent="center" sx={{width: { xs: '100%', sm: '80%', md: '80%', lg: '60%', xl: '60%' }, height: { xs: '100%', sm: '80%', md: '80%', lg: '60%', xl: '60%' }}}>
              {articles.map((article) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={4}
                  key={article.id}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexWrap: "wrap",                                      
                  }}
                >
                  <Card
                    sx={{
                      maxWidth: "380px",
                      maxHeight: "380px",
                      width: "40vw",
                      height: "40vw",
                      borderRadius: "16px",
                      boxShadow: 3, // Add shadow for depth
                      "&:hover": {
                        boxShadow: 6, // Increase shadow on hover
                      },
                      "@media (max-width: 1200px) and (min-width: 601px)": {
                        maxWidth: "40vw", // Adjust for medium screens
                        width: "40vw",
                        height: "40vw",
                      },
                      "@media (max-width: 600px)": {
                        maxWidth: "80vw",
                        width: "80vw",
                        height: "80vw",
                      },
                    }}
                  >
                    <CardMedia
                      component="img"
                      height="140px"
                      image={
                        article.attributes.photo.data.attributes.formats
                          .thumbnail.url
                      }
                      alt="Article Image"
                      sx={{
                        height: "6.67vw",
                        width: "6.67vw",
                        borderRadius: "50%",
                        objectFit: "contain",
                        objectPosition: "center",
                        margin: "10px",
                        display: "block",
                        mx: "auto",
                        //padding: '5px',
                        boxShadow: "0 4px 8px rgba(0, 0, 0.2, 0.2)", // Soft shadow as border
                        "@media (max-width: 1200px) and (min-width: 601px)": {
                          width: "13.33vw",
                          height: "13.33vw",
                        },
                        "@media (max-width: 600px)": {
                          width: "26.67vw",
                          height: "26.67vw",
                        },
                      }}
                    />
                    <CardContent sx={{
                      height: "13.33vw",
                      margin: "auto",
                      width: "80%",
                    }}>
                      <Typography gutterBottom variant="h6" component="div">
                        <Link
                          to={`/post/${article.attributes.url}`}
                          style={{ textDecoration: "none", color: "inherit" }}
                        >
                          {article.attributes.title}
                        </Link>
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                          {truncate(article.attributes.description, 60)}
                        </ReactMarkdown>
                      </Typography>
                      <Typography variant="body2" sx={{ mt: 2 }}>
                        <Link
                          to={`/post/${article.attributes.url}`}
                          style={{ textDecoration: "none" }}
                        >
                          See more...
                        </Link>
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        </div>

        <Footer />
      </div>
    </ThemeProvider>
  );
}

export default PostList;
