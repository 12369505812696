import './App.css';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import { Link } from 'react-router-dom';
import logolarge from './images/ftfw.png';
import HomeHeader from './HomeHeader';
import HomeFooter from './HomeFooter';
import { Button, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import ScrollTop from './ScrollTop';


function Home() {
  useEffect(() => {
    document.title = "Finding the Formula"
  });
  return (
    <ThemeProvider theme={theme}>
    <div className="App">
      <HomeHeader />
      <ScrollTop/>
        <section className="main-section" style={{ backgroundColor: '#002c52', color: '#ffffff' }}>
        <div className="logo-container">
          <img src={logolarge} alt="logo" className="logo-img" style={{ height: 'auto' }} />
          <Typography variant="body1" component="p" style={{ marginTop: '25px', marginBottom: '25px'}}></Typography>
          <Link to="/post">
          <Button variant="contained" className="home-link" color="secondary" size="large" style={{fontSize: '1.2rem', fontFamily: 'Multicolore', borderRadius: '0.3rem'}}>Learn More</Button>
          </Link>
        </div>
      </section>

      <HomeFooter />
    </div>
    </ThemeProvider>
  );
}

export default Home;