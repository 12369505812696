import './App.css';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import logolarge from './images/ftf.png';
import Header from './Header';
import Footer from './Footer';
import { useState, useEffect } from 'react';
import Typography from "@mui/material/Typography";



function About() {
  useEffect(() => {
    document.title = "About - Finding the Formula"
  });
  return (
    <ThemeProvider theme={theme}>
    <div className="App">
      <Header />

      <section className="main-section" style={{ backgroundColor: '#FFFFFF', color: '#002c52' }}>
        <div className="logo-container">
          <img src={logolarge} alt="logo" className="logo-img" style={{ width: '40vw', height: 'auto' }} />
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>  
          <Typography variant="body1" component="p" style={{ marginTop: '25px', marginBottom: '25px'}}>Exploring the meaning and means of success. Find exclusive profiles and interviews here!</Typography>
        </div>
      </section>

      <Footer />
    </div>
    </ThemeProvider>
  );
}

export default About;
