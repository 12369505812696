import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { ThemeProvider } from "@mui/material/styles";
import Header from './Header';
import Footer from './Footer';
import theme from './theme';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { Link } from 'react-router-dom';
import NotFound from './NotFound';
import ScrollTop from './ScrollTop';

const truncate = (str, n) => {
  return str.length > n ? str.substr(0, n - 1) + "..." : str;
};

const Tags = () => {
  const { tag } = useParams();
  const [articles, setArticles] = useState([]);
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await axios.get(
          `https://api.findingtheformula.com/api/articles?filters[tags][name][$eq]=${tag}&populate=photo&populate=tags`
        );
        if (response.data && response.data.data.length > 0) {
          document.title = (tag+' - Finding the Formula')
          setArticles(response.data.data);
        } else {
        console.log('No articles found for this tag.');
        setNotFound(true);
      }
    }  catch (error) {
        console.error("Failed to fetch articles:", error);
        setNotFound(true);
      }
    };

    fetchArticles();
  }, [tag]);


  if (notFound) {
    console.log('Rendering NotFound component');
    return <NotFound />;}

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Header />
        <ScrollTop/>
        <div className="main-section">
        <Box
          sx={{
            width: "60vw",
            margin: "auto",
            marginTop: "50px",
            marginBottom: "50px",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "flex-start",
          }}
        >
          <Grid container spacing={2}>
            {articles.map((article) => (
              <Grid item xs={12} sm={6} md={4} key={article.id}>
                <Card
                  sx={{
                    width: "18vw",
                    height: "18vw",
                  }}
                >
                  <CardContent>
                    <Typography variant="h3" component="div">
                        <Link to={`/post/${article.attributes.url}`} style={{ textDecoration: 'none' }}>{article.attributes.title}</Link>
                    </Typography>
                    <Typography variant="body2" color="text.secondary" >
                        <ReactMarkdown rehypePlugins={[rehypeRaw]}>{truncate(article.attributes.description, 60)}</ReactMarkdown>
                    </Typography>
                    <Typography variant="body2">
                        <Link to={`/post/${article.attributes.url}`}>See more...</Link>
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
        </div>
        <Footer />
      </div>
    </ThemeProvider>
  );
};

export default Tags;
