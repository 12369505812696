import "./App.css";
import { useState, useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import { useParams } from "react-router-dom";
import axios from "axios";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import Chip from "@mui/material/Chip";
import Header from "./Header";
import Footer from "./Footer";
import NotFound from "./NotFound";
import { Link } from "react-router-dom";
import ScrollTop from "./ScrollTop";

function Post() {
  const [post, setPost] = useState(null);
  const { url } = useParams();

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await axios.get(
          `https://api.findingtheformula.com/api/articles?filters[url][$eq]=${url}&populate=photo&populate=tags`
        );
        if (response.data) {
          setPost(response.data.data[0]);
          document.title =
            response.data.data[0].attributes.title + " - Finding the Formula";
        }
      } catch (error) {
        console.error("Error fetching post:", error);
      }
    };

    fetchPost();
  }, [url]);

  if (!post || !post.attributes) {
    return <NotFound />;
  }
  /*<Link to={`/post/${article.attributes.url}`} style={{ textDecoration: 'none' }}>{article.attributes.title}</Link>*/
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Header />
        <ScrollTop />
        <section
          className="main-section"
          style={{ backgroundColor: "#FFFFFF", color: "#001C29" }}
        >
          <div key={post.id}>
            <div className="avatar">
              <img
                src={`${post.attributes.photo.data.attributes.formats.small.url}`}
                alt={post.attributes.photo.data.attributes.name}
              />
            </div>

            <h1>{post.attributes.title}</h1>

            <p>&nbsp;</p>
            <div className="author-date-tags-section">
              <div className="author-date">
                <div className="author">{post.attributes.author}</div>
                <div className="date">{post.attributes.date}</div>
              </div>
              <div className="tags">
                {post.attributes.tags.data.map((tag) => (
                  <Link
                    to={`/tags/${tag.attributes.name}`}
                    style={{ textDecoration: "none" }}
                  >
                    <Chip
                      key={tag.id}
                      label={tag.attributes.name}
                      sx={{ cursor: "pointer" }}
                      size="small"
                      color="primary"
                      className="tag"
                    />
                  </Link>
                ))}
              </div>
            </div>
            <p>&nbsp;</p>
            <div className="content">
              <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                {post.attributes.body}
              </ReactMarkdown>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </ThemeProvider>
  );
}

export default Post;
