import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Button,
  Menu,
  MenuItem,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  Collapse,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";
import { useState } from "react";
import logo2 from "./images/ftfv3w.gif";

const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [submenuOpen, setSubmenuOpen] = useState(false);
  const [submenuClose, setSubmenuClose] = useState(false);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm")); // Detecting small screen size

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setSubmenuOpen(false);
  };

  const handleSubmenuOpen = () => {
    setSubmenuOpen(!submenuOpen);
  };

  const handleSubmenuClose = () => {
    setSubmenuClose(!submenuClose);
  };

  return (
    <AppBar position="fixed" style={{ backgroundColor: "#002c52" }}>
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          component={Link}
          to="/"
        >
          <img src={logo2} alt="logo" style={{ maxHeight: "45px" }} />
        </IconButton>
        <div style={{ display: "flex", marginLeft: "auto" }}>
          {isSmallScreen ? (
            <IconButton edge="start" color="inherit" onClick={handleDrawerOpen}>
              <MenuIcon />
            </IconButton>
          ) : (
            <>
              <Button
                color="inherit"
                style={{ color: "#FFFFFF" }}
                component={Link}
                to="/"
                className="home-link"
              >
                <Typography variant="h6">Home</Typography>
              </Button>
              <Button
                color="inherit"
                style={{ color: "#FFFFFF" }}
                component={Link}
                to="/formulas"
                className="formulas-link"
              >
                <Typography variant="h6">Formulas</Typography>
              </Button>
              <Button
                color="inherit"
                style={{ color: "#FFFFFF" }}
                endIcon={<ArrowDropDownIcon />}
                onClick={handleMenuOpen}
              >
                <Typography variant="h6">About</Typography>
              </Button>
              <Menu
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                keepMounted
              >
                <MenuItem
                  onClick={handleMenuClose}
                  component={Link}
                  to="/about"
                >
                  <Typography variant="h6">About</Typography>
                </MenuItem>
                <MenuItem
                  onClick={handleMenuClose}
                  component={Link}
                  to="/contact"
                >
                  <Typography variant="h6">Contact</Typography>
                </MenuItem>
              </Menu>
            </>
          )}
        </div>
      </Toolbar>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => {
          handleDrawerClose();
          handleSubmenuClose(); // Collapse the submenu when closing the drawer
        }}
        PaperProps={{ style: { backgroundColor: theme.palette.primary.main } }}
      >
        <List>
        <ListItem
            ListItemButton
            onClick={handleDrawerClose}
            component={Link}
            to="/"
          >
            <ListItemText
              primary="Home"
              primaryTypographyProps={{
                style: {
                  color: theme.palette.secondary.main,
                  fontFamily: "Multicolore",
                },
              }}
            />
          </ListItem>
          <ListItem
            ListItemButton
            onClick={handleDrawerClose}
            component={Link}
            to="/formulas"
          >
            <ListItemText
              primary="Formulas"
              primaryTypographyProps={{
                style: {
                  color: theme.palette.secondary.main,
                  fontFamily: "Multicolore",
                },
              }}
            />
          </ListItem>
          <Divider />
          <ListItem ListItemButton onClick={handleSubmenuOpen}>
            <ListItemText
              primary="About"
              primaryTypographyProps={{
                style: {
                  color: theme.palette.secondary.main,
                  fontFamily: "Multicolore",
                },
              }}
            />
            <ArrowDropDownIcon
              style={{ color: theme.palette.secondary.main }}
            />
          </ListItem>
          <Collapse in={submenuOpen} timeout="auto" unmountOnExit>
            <List
              component="div"
              disablePadding
              style={{ backgroundColor: theme.palette.secondary.light }}
            >
              {" "}
              {/* Different background for submenu */}
              <ListItem
                button
                component={Link}
                to="/about"
                onClick={handleDrawerClose}
              >
                <ListItemText
                  primary="About"
                  style={{
                    color: theme.palette.primary.main,
                    fontFamily: "YourFontFamily",
                    fontSize: "0.9rem",
                  }} // Indentation and smaller font size
                />
              </ListItem>
              <ListItem
                button
                component={Link}
                to="/contact"
                onClick={handleDrawerClose}
              >
                <ListItemText
                  primary="Contact"
                  style={{
                    color: theme.palette.primary.main,
                    fontFamily: "YourFontFamily",
                    fontSize: "0.9rem",
                  }} // Indentation and smaller font size
                />
              </ListItem>
            </List>
          </Collapse>
        </List>
      </Drawer>
    </AppBar>
  );
};

export default Header;
