import React from 'react';
import Home from './Home';
import Post from './Post';
import Tags from './Tags';
import About from './About';
import Contact from './Contact';
import Map from './Map';
import Cities from './Cities';
import Formulas from './Formulas';
import Interviews from './Interviews';
import TagList from './TagList';
import PostList from './PostList';
import NotFound from './NotFound';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/post/:url" element={<Post />} />
        <Route path="/tags/:tag" element={<Tags />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/map" element={<Map/>} />
        <Route path="/cities/:city" element={<Cities/>} />
        <Route path="/formulas" element={<Formulas />} />
        <Route path="/interviews" element={<Interviews />} />
        <Route path="/tags" element={<TagList/>} />
        <Route path="/posts" element={<PostList/>} />
        <Route path="/notfound" element={<NotFound />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
