import React from 'react';

const HomeFooter = () => {
  return (
    <footer className="footer" style={{ backgroundColor: '#ffffff', color: '#002c52' }}>
      <p>&copy; Morlyn & Co. 2023</p>
    </footer>
  );
};

export default HomeFooter;
