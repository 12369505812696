import './App.css';
import { useState, useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import Chip from '@mui/material/Chip';
import Header from './Header';
import Footer from './Footer';
import NotFound from './NotFound';
import { Link } from 'react-router-dom';
import ScrollTop from './ScrollTop';
import { Container, Box } from '@mui/material';
import image1 from './images/image1.jpg';


function Interviews() {
    const [post, setPost] = useState(null);
    const { url } = useParams();

    const truncate = (str, n) => {
        return str.length > n ? str.substr(0, n - 1) + "..." : str;
      };

    useEffect(() => {
        const fetchPost = async () => {
          try {
            const response = await axios.get(`https://api.findingtheformula.com/api/articles?filters[url][$eq]=example&populate=photo&populate=tags`);
            if (response.data) {
              setPost(response.data.data[0]);
              document.title = (response.data.data[0].attributes.title+' - Finding the Formula')
            }
          } catch (error) {
            console.error('Error fetching post:', error);
          }
        };
        
          
        fetchPost();
      }, [url]);
  
  return (
    <ThemeProvider theme={theme}>
      <div className="App" style = {{width: { xs: "95vw", sm: "80vw", md: "60vw", lg: "25vw" }, height: '100vw', }}>
        <section className="main-section-example" style={{ backgroundColor: '#ffffff', color: '#002c52', display: 'inline-flex', alignItems: 'flex-start', justifyContent: 'center'}}>
          <Container sx={{width: '100%' }}>
            <div style={{height: '10vh', overflow: 'hidden',}}>
          <h2>{post ? post.attributes.formulizer : 'Loading...'}</h2>
          </div>
          <Container sx={{ padding: "0", display: "flex", flexWrap: "nowrap", gap:"2vw", justifyContent: 'center', alignItems: 'center'}}>
            <Box sx={{
                  bgcolor: theme.palette.secondary.main,
                  color: theme.palette.primary.main,
                  borderRadius: "0.75rem",
                  display: "flex",
                  flexWrap: "wrap",
                  width: '40%',
                  height: '10%',
                  overflow: 'hidden',
                }}>
                    <h6 style={{textAlign: 'left'}}>{post ? post.attributes.description : 'Loading...'}</h6>
                    {/*<p><ReactMarkdown rehypePlugins={[rehypeRaw]}>{truncate(post ? post.attributes.body : 'Loading...', 60)}</ReactMarkdown></p>*/}
            </Box>
            <Box
                sx={{
                  bgcolor: theme.palette.secondary.main,
                  color: theme.palette.primary.main,
                  borderRadius: "0.75rem",
                  padding: "2vh",
                  display: "inline-flex",
                  flexWrap: "nowrap",
                  width: "auto",
                  height: "auto",
                  overflow: "hidden",
                }}
              >
                <Link to="/map">
                  <div className="avatar">
                <img src={`${post ? post.attributes.photo.data.attributes.url : 'Loading...'}`} alt={post ? post.attributes.photo.data.attributes.name : 'Loading...'} style={{borderRadius: "20rem",}}/>
              </div>
                </Link>
              </Box>
          </Container>
          </Container>
        </section>

      </div>
    </ThemeProvider>
  );
}

export default Interviews;
