import React, { useEffect, useState } from 'react';
import './App.css';
import { ThemeProvider, styled } from '@mui/material/styles';
import theme from './theme';
import Header from './Header';
import Footer from './Footer';
import axios from 'axios';
import coordinates from './cities.json';
import { ComposableMap, Geographies, Geography, Marker } from "react-simple-maps";
import usAlbersJson from './us-albers.json';
import { Link } from 'react-router-dom';
import ScrollTop from './ScrollTop';
import Button from '@mui/material/Button';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';


function Map() {
    const [articles, setArticles] = useState([]);
    const [articleCountByCity, setArticleCountByCity] = useState({});
    const [articlesByCity, setArticlesByCity] = useState({});
    const LightTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
      ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
          backgroundColor: theme.palette.common.white,
          color: 'rgba(0, 0, 0, 0.87)',
          boxShadow: theme.shadows[1],
          fontSize: 11,
        },
      }));

    // Read cities.json and create an object for easy lookup
    const cityCoordinates = {};
    coordinates.forEach(item => {
      const key = `${item.city}-${item.state}`;
      cityCoordinates[key] = { lat: item.lat, long: item.long };
    });
  
    useEffect(() => {
      document.title = "Map - Finding the Formula";
  
      axios.get('https://api.findingtheformula.com/api/articles?populate=photo&populate=tags&populate=city&populate=state&populate=country')
      .then(response => {
        console.log(response.data.data);
  
        const articlesArray = response.data.data || [];
  
        const cityArticleCount = {};
        articlesArray.forEach(article => {
          const city = article.attributes.city?.data?.attributes?.city;
          const state = article.attributes.state?.data?.attributes?.state;
          const key = `${city}-${state}`;
          
          cityArticleCount[key] = (cityArticleCount[key] || 0) + 1;
        });
        setArticleCountByCity(cityArticleCount);
        
        
        const articlesWithCoordinates = articlesArray.map(article => {
          const city = article.attributes.city?.data?.attributes?.city;
          const state = article.attributes.state?.data?.attributes?.state;
          const cityurl = article.attributes.city?.data?.attributes?.cityurl;
          const cityStateKey = `${city}-${state}`;
          const testarticle = articlesArray
          
          
          console.log('City:', city, 'State:', state, 'Key:', cityStateKey, 'Coordinates:', cityCoordinates[cityStateKey]);
          console.log(testarticle)
          return {
            ...article,
            lat: cityCoordinates[cityStateKey]?.lat,
            long: cityCoordinates[cityStateKey]?.long
          };
        });
        setArticles(articlesWithCoordinates);
        const articlesByCity = {};
        const articlesByCityLocal = {};
        articlesWithCoordinates.forEach(article => {
          const city = article.attributes.city?.data?.attributes?.city;
          const state = article.attributes.state?.data?.attributes?.state;
          const key = `${city}-${state}`;
          if (!articlesByCityLocal[key]) {
            articlesByCityLocal[key] = [];
          }
          articlesByCityLocal[key].push(article);
        });
        setArticlesByCity(articlesByCityLocal);
      });
    }, []);
  

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Header />
        <ScrollTop/>
        <section
          className="map-section"
          style={{ backgroundColor: "#FFFFFF", color: "#002c52" }}
        >
          <div className="map-container">
            <ComposableMap projection="geoAlbersUsa">
              <Geographies geography={usAlbersJson}>
                {({ geographies }) =>
                  geographies.map((geo) => (
                    <Geography
                      key={geo.rsmKey}
                      geography={geo}
                      fill="#002c52"
                      stroke="#ffffff"
                      strokeWidth={"0.1rem"}
                      style={{
                        default: { outline: "none" },
                        hover: { outline: "none" },
                        pressed: { outline: "none" },
                      }}
                    />
                  ))
                }
              </Geographies>
              {articles.map((article) => {
              const city = article.attributes.city?.data?.attributes?.city;
              const state = article.attributes.state?.data?.attributes?.state;
              const key = `${city}-${state}`;
              const radius = articleCountByCity[key] +4 || 6; // Set the default size if no count is found
              const articlelisttitle = article.attributes.title
              const firstFiveArticles = articlesByCity[key]?.slice(0, 5) || [];
              return (
                <Link to={`/cities/${article.attributes.city?.data?.attributes?.cityurl}`} key={article.id}>
                  <LightTooltip title={
          <div>
            {firstFiveArticles.map((article, index) => (
              <div key={index}>{article.attributes.title}</div>
            ))}
          </div>
        }>
                  <Marker key={article.id} coordinates={[article.long, article.lat]}>
                    <circle r={radius} fill="#F35BBA" stroke="#000" strokeWidth={0} />
                  </Marker>
                  
              </LightTooltip>
                </Link>
              );
            })}
            </ComposableMap>
            {/* Additional content */}
          </div>
        </section>

        <Footer />
      </div>
    </ThemeProvider>
  );
}

export default Map;
