import './App.css';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import logolarge from './images/ftf.png';
import Header from './Header';
import Footer from './Footer';
import { useState, useEffect } from 'react';
import Typography from "@mui/material/Typography";
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Chip from '@mui/material/Chip';


function TagList() {
  const [tags, setTags] = useState(null);
  const { url } = useParams();

  useEffect(() => {
    const fetchTags = async () => {
      try {
        const response = await axios.get(`https://api.findingtheformula.com/api/tags`);
        if (response) {
          setTags(response.data);
          console.log(response)
        } else {
          console.log("No response")
        }
      } catch (error) {
        console.error('Error fetching tags:', error);
      }
    };
    fetchTags();
    document.title = "Tags - Finding the Formula"
  }, []);
  return (
    <ThemeProvider theme={theme}>
    <div className="App">
      <Header />

      <section className="main-section" style={{ backgroundColor: '#FFFFFF', color: '#002c52' }}>
        <div className="logo-container">
          <img src={logolarge} alt="logo" className="logo-img" style={{ width: '40vw', height: 'auto' }} />
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>  
          <Typography variant="body1" component="p" style={{ marginTop: '25px', marginBottom: '25px'}}>Browse Articles by Tag:</Typography>
          <div className="taglist">
                  {tags && tags.data && tags.data.map((tag) => (
                    <Link to={`/tags/${tag.attributes.name}`} style={{ textDecoration: 'none' }}>
                        <Chip key={tag.id} label={tag.attributes.name} sx={{ cursor: 'pointer' }} size="small" color="primary" className="tag" />
                    </Link>
                  ))}
                </div>
        </div>
      </section>

      <Footer />
    </div>
    </ThemeProvider>
  );
}

export default TagList;
