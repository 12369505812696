import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#002c52',
    },
    secondary: {
      main: '#ffffff',
    },
    fadedgrey: {
      main: '#00000099',
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: '0.3rem'
        },
      },
    },


    },
  typography: {
    fontFamily: 'Poppins, sans-serif',
    h1: {
      fontFamily: 'Multicolore, sans-serif',
      fontSize: '3rem',
      fontWeight: 700,
    },
    h2: {
      fontFamily: 'Multicolore, sans-serif',
      fontSize: '2.5rem',
      fontWeight: 600,
    },
    h3: {
      fontFamily: 'Multicolore, sans-serif',
      fontSize: '2rem',
      // Add more header styles as needed
    },
    h4: {
      fontFamily: 'Multicolore, sans-serif',
      fontSize: '1.75rem',
      // Add more header styles as needed
    },
    h5: {
      fontFamily: 'Multicolore, sans-serif',
      fontSize: '1.5rem',
      // Add more header styles as needed
    },
    h6: {
      fontFamily: 'Multicolore, sans-serif',
      fontSize: '1rem',
    },
    p: {
      fontFamily: 'Poppins, sans-serif',
      fontSize: '1rem',
    }
    // Set the rest of the typography styles to Poppins
    // Add more typography settings as needed
  },
  // Add more theme customizations as needed
});

export default theme;
