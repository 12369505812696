import React from 'react';

const Footer = () => {
  return (
    <footer id="footer2" className="footer2" style={{ backgroundColor: '#002C52', color: '#FFFFFF' }}>
      <p>&copy; Morlyn & Co. 2023</p>
    </footer>
  );
};

export default Footer;
